import { DEFAULT_LANGUAGE, Language, LANGUAGE_COOKIE_KEY } from '@workspace/shared-constants';
import Cookie from 'js-cookie';

const getInitialLang = (): string => {
	const isClient = typeof window !== 'undefined';

	if (!isClient) {
		return Language.RU;
	}

	let locale = Cookie.get(LANGUAGE_COOKIE_KEY);

	if (!locale && navigator.languages.length) {
		const browserLocale = navigator.languages[0];
		locale = browserLocale.startsWith(Language.RU) ? Language.RU : Language.EN;
	}

	return locale || DEFAULT_LANGUAGE;
};

export default getInitialLang;
