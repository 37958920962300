import { PermissionAction, PermissionSubject } from '@workspace/permissions';

export const permissionElements = {
	// pages
	adminDictionaryPage: ['read_list_of_dictionaries', 'dictionary'],
	adminSelectionPage: ['read_list', 'selection'],
	adminBusinessProcessPage: ['read_list', 'business_process'],
	adminCertificatesPage: ['read_list', 'certificate_template'],
	adminNotificationTemplatePage: ['read_list', 'notification_template'],
	adminRoadmapTemplatePage: ['read_list', 'roadmap_template'],
	adminCollegialPage: ['read_list', 'collegial'],
	adminRolePage: ['read_list', 'role'],
	adminProjectTemplatePage: ['read_list', 'project_template'],
	adminCriteriaPage: ['read_list', 'criteria'],
	adminConsentPage: ['read_list', 'consent'],
	expertisePage: ['read', 'expertise'],
	expertsListPage: ['read_list', 'expert'],
	expertiseArchiveListPage: ['read', 'expertise'],
	expertiseCurrentListPage: ['read', 'expertise'],
	expertiseNewListPage: ['read', 'expertise'],
	userProfileUpdatePage: ['update', 'user'],
	projectsListPage: ['read_list', 'project'],
	partnersListPage: ['read_list', 'partner'],
	projectEditPage: ['update', 'project'],
	projectEstimatePage: ['read', 'evaluation'],
	projectEstimateUpdatePage: ['update', 'evaluation'],
	projectExpertisePage: ['read', 'expertise'],
	projectHistoryPage: ['read', 'project_changes_log'],
	projectPasportPage: ['read', 'project_passport'],
	projectRequestPage: ['read', 'project'],
	projectRoadmapPage: ['read', 'roadmap'],
	projectStatusPage: ['read', 'project_status_log'],
	projectUploadPage: ['manage', 'all'],
	reportsPage: ['read', 'report'],
	selectionsPage: ['read_list', 'selection'],
	usersListPage: ['read_list', 'user'],
	draftsPage: ['read', 'project_draft'],
	personalAccountApplicationsPage: ['read', 'project'],
	personalAccountCertificatesPage: ['read', 'certificate'],
	personalAccountConsentsPage: ['read_list', 'consent'],
	personalAccountExpertisesPage: ['read', 'expertise'],
	personalAccountMeetingsPage: ['read', 'collegial'],
	personalAccountProfilePage: ['read', 'user'],
	personalAccountProjectsPage: ['read', 'project'],
	personalAccountTemplatesPage: ['read', 'user'], // ?
	formProjectCreate: ['create', 'project'],
	formProjectCreateDraft: ['create', 'project_draft'],

	personalAccountCompetenciesRead: ['read', 'expert'],
	personalAccountCompetenciesUpdate: ['update', 'expert'],

	// subjects
	adminDictionaryLink: ['read_list_of_dictionaries', 'dictionary'],
	adminSelectionLink: ['read_list', 'selection'],
	adminBusinessProcessLink: ['read_list', 'business_process'],
	adminCertificatesLink: ['read_list', 'certificate_template'],
	adminNotificationTemplateLink: ['read_list', 'notification_template'],
	adminRoadmapTemplateLink: ['read_list', 'roadmap_template'],
	adminCollegialLink: ['read_list', 'collegial'],
	adminRoleLink: ['read_list', 'role'],
	adminProjectTemplateLink: ['read_list', 'project_template'],
	adminCriteriaLink: ['read_list', 'criteria'],
	adminConsentLink: ['read_list', 'consent'],

	collegialReadLink: ['read', 'collegial'],
	collegialVotingPage: ['read', 'collegial'],
	collegialVotingAdminPage: ['update', 'collegial'],
	collegialCreateButton: ['create', 'collegial'],
	collegialUpdateButton: ['update', 'collegial'],
	collegialDeleteButton: ['delete', 'collegial'],

	businessProcessCreateButton: ['create', 'business_process'],
	businessProcessReadLink: ['read', 'business_process'],
	businessProcessDuplicateButton: ['create', 'business_process'],
	businessProcessUpdateButton: ['update', 'business_process'],
	businessProcessDeleteButton: ['delete', 'business_process'],
	businessProcessAuthorLink: ['read', 'user'],

	certificateTemplateCreateButton: ['create', 'certificate_template'],
	certificateTemplateCreateMailingButton: ['mailing', 'certificate_template'],
	certificateTemplateReadLink: ['read', 'certificate_template'],
	certificateTemplateDuplicateButton: ['create', 'certificate_template'],
	certificateTemplateDeleteButton: ['delete', 'certificate_template'],
	certificateTemplateUpdateButton: ['update', 'certificate_template'],
	certificateTemplateUpdateBackgroundButton: ['update_background', 'certificate_template'],
	certificateTemplateDeleteBackgroundButton: ['delete_background', 'certificate_template'],
	certificateTemplateAuthorLink: ['read', 'user'],
	certificateTemplatePreview: ['preview', 'certificate_template'],

	certificateUserUpdate: ['update', 'certificate'],
	certificateUserPreview: ['preview', 'certificate'],

	consentCreateButton: ['create', 'consent'],
	consentAuthorLink: ['read', 'user'],
	usersConsents: ['read_user_consent', 'consent'],
	usersConsentsDelete: ['remove_user_data', 'consent'],

	criteriaReadLink: ['read', 'criteria'],
	criteriaCreateButton: ['create', 'criteria'],
	criteriaDuplicateButton: ['create', 'criteria'],
	criteriaUpdateButton: ['update', 'criteria'],
	criteriaDeleteButton: ['delete', 'criteria'],
	criteriaAuthorLink: ['read', 'user'],

	dictionaryElementCreateButton: ['create_element', 'dictionary'],
	dictionaryElementDuplicateButton: ['create_element', 'dictionary'],
	dictionaryElementUpdateButton: ['update_element', 'dictionary'],
	dictionaryElementDeleteButton: ['delete_element', 'dictionary'],
	dictionaryElementsLink: ['read_list', 'dictionary'],
	dictionaryAuthorLink: ['read', 'user'],
	dictionaryElementStats: ['read_stats', 'dictionary'],

	notificationTemplateCreateButton: ['create', 'notification_template'],
	notificationTemplateReadLink: ['read', 'notification_template'],
	notificationTemplateDuplicateButton: ['create', 'notification_template'],
	notificationTemplateUpdateButton: ['update', 'notification_template'],
	notificationTemplateDeleteButton: ['delete', 'notification_template'],
	notificationTemplateAuthorLink: ['read', 'user'],

	projectTemplateCreateButton: ['create', 'project_template'],
	projectTemplateDuplicateButton: ['create', 'project_template'],
	projectTemplateUpdateButton: ['update', 'project_template'],
	projectTemplateDeleteButton: ['delete', 'project_template'],
	projectTemplateAuthorLink: ['read', 'user'],

	roadmapTemplateReadLink: ['read', 'roadmap_template'],
	roadmapTemplateCreateButton: ['create', 'roadmap_template'],
	roadmapTemplateDuplicateButton: ['create', 'roadmap_template'],
	roadmapTemplateUpdateButton: ['update', 'roadmap_template'],
	// TODO
	// roadmapTemplateDeleteButton: ['delete', 'roadmap_template'],
	roadmapTemplateAuthorLink: ['read', 'user'],

	// Roadmap document
	// View
	roadmapDocumentViewScan: ['view_scan', 'roadmap_document'],
	roadmapDocumentViewConsent: ['view_consent', 'roadmap_document'],
	roadmapDocumentViewOther: ['view_other', 'roadmap_document'],
	// Upload
	roadmapDocumentUploadScan: ['upload_scan', 'roadmap_document'],
	roadmapDocumentUploadConsent: ['upload_consent', 'roadmap_document'],
	roadmapDocumentUploadOther: ['upload_other', 'roadmap_document'],
	// Delete
	roadmapDocumentDeleteScan: ['delete_scan', 'roadmap_document'],
	roadmapDocumentDeleteConsent: ['delete_consent', 'roadmap_document'],
	roadmapDocumentDeleteOther: ['delete_other', 'roadmap_document'],
	// Replace
	roadmapDocumentReplaceScan: ['replace_scan', 'roadmap_document'],
	roadmapDocumentReplaceConsent: ['replace_consent', 'roadmap_document'],
	roadmapDocumentReplaceOther: ['replace_other', 'roadmap_document'],
	// Update
	roadmapDocumentUpdateScan: ['update_scan', 'roadmap_document'],
	roadmapDocumentUpdateConsent: ['update_consent', 'roadmap_document'],
	roadmapDocumentUpdateOther: ['update_other', 'roadmap_document'],

	reportsExport: ['read', 'export'],

	roleReadLink: ['read', 'role'],
	roleCreateButton: ['create', 'role'],
	roleDuplicateButton: ['create', 'role'],
	roleUpdateButton: ['update', 'role'],
	roleDeleteButton: ['delete', 'role'],

	selectionCreateButton: ['create', 'selection'],
	selectionReadLink: ['read', 'selection'],
	selectionDuplicateButton: ['create', 'selection'],
	selectionUpdateButton: ['update', 'selection'],
	selectionDeleteButton: ['delete', 'selection'],
	selectionAuthorLink: ['read', 'user'],
	selectionClose: ['close', 'selection'],
	selectionExport: ['read', 'selection'],

	evaluationUpdateButton: ['update', 'evaluation'],
	evaluationUpdateCompletedButton: ['update_completed', 'evaluation'],
	expertiseExpertListDownloadButton: ['export', 'expert'],
	expertiseCreateModal: ['create', 'expertise'],
	expertiseCreateButton: ['create', 'expertise'],
	changeOperatorButton: ['change_operator', 'head_tracker'],
	expertiseWorkflow: ['work', 'expertise'],
	expertiseCriterias: ['read', 'criteria'],

	projectPageDownloadApplicationButton: ['export', 'project'],
	projectPageDownloadRoadmapButton: ['export', 'roadmap'],
	projectPageDownloadPasportButton: ['export', 'project_passport'],
	projectPageDownloadCapApplications: ['project_first_level_criteria', 'project'],
	projectPageSaveRoadmapAsTemplateButton: ['create', 'roadmap_template'],
	projectPageCloneProjectButton: ['create', 'project'],
	projectPageUpdateButton: ['update', 'project'],
	draftProjectUpdateButton: ['update', 'draft_project'],
	projectPageUpdateRightsButton: ['update_rights', 'project'],

	projectPageRoadmapCreate: ['create', 'roadmap'],
	projectPageRoadmapUpdate: ['update', 'roadmap'],
	projectPageRoadmapView: ['read', 'roadmap'],

	projectPageRoadmapTaskRead: ['read', 'roadmap_task'],
	projectPageRoadmapTaskCreate: ['create', 'roadmap_task'],
	projectPageRoadmapTaskUpdate: ['update', 'roadmap_task'],
	projectPageRoadmapTaskFinish: ['finish', 'roadmap_task'],
	projectPageRoadmapTaskDelete: ['delete', 'roadmap_task'],

	projectPagePasportCreate: ['create', 'project_passport'],
	projectPagePasportUpdate: ['update', 'project_passport'],
	projectPagePasportView: ['read', 'project_passport'],

	projectPageExpertiseView: ['read', 'expertise'],

	projectPageEvaluationView: ['read', 'evaluation'],

	projectPageStatusTabView: ['read', 'project_status_log'],
	projectPageHistoryTabView: ['read', 'project_changes_log'],
	projectPageStatusProtocolEdit: ['update_protocol', 'project_status_log'],

	projectPageQuickEdit: ['quick_edit', 'project'],

	projectUpdateTracker: ['update_tracker', 'project'],
	projectUpdateOperator: ['update_operator', 'project'],

	projectsImport: ['import', 'project'],
	projectsCreateButton: ['create', 'project'],
	projectsExportButton: ['export', 'project'],
	projectsMultipleUpdate: ['quick_edit', 'project'],
	projectsActualSelections: ['read_actual_list', 'selection'],
	projectsPasportTab: ['read', 'project_passport'],

	projectStatusLogProtocolDownload: ['download_protocol', 'project_status_log'],

	evaluationCreateButton: ['create', 'evaluation'],
	evaluationUpdate: ['update', 'evaluation'],

	expertExpertiseExportButton: ['export', 'expertise'],
	expertExpertiseStatistic: ['read', 'expertise'],
	expertiseRemind: ['remind', 'expertise'],
	expertiseCancel: ['cancel', 'expertise'],
	expertiseChangeCompletionDate: ['change_completion_date', 'expertise'],
	expertiseCompletedUpdate: ['update', 'expertise'],

	updateExpertiseButton: ['update', 'expertise'],

	collegialViewAdminMode: ['read_voting_results', 'collegial'],
	collegialView: ['read', 'collegial'],
	collegialHistory: ['read_logs', 'collegial'],
	collegialGenerateProtocol: ['generate_protocol', 'collegial'],
	collegialDeleteProtocol: ['delete_protocol', 'collegial'],
	collegialThirdPartyVoting: ['third_party_voting', 'collegial'],
	collegialChangeMeetingStatus: ['change_meeting_status', 'collegial'],
	collegialSubscribeToVotingResults: ['subscribe_to_voting_results', 'collegial'],

	partnerRead: ['read', 'partner'],
	partnerCreate: ['create', 'partner'],
	partnerUpdate: ['update', 'partner'],
	partnerDelete: ['delete', 'partner'],

	authorLink: ['read', 'user'],

	all: ['manage', 'all'],
} as const;
export type TYPE_CONST = PermissionAction | PermissionSubject;
// } satisfies Record<string, [PermissionAction, PermissionSubject]>;

export type PermissionElement = keyof typeof permissionElements;
